import React, { useEffect } from "react"
import Layout from "../components/layout";
import { navigate } from "gatsby"

const NotFoundPage = () => {
  useEffect(() => {
    navigate('/');
  }, [])
  return null
}

export default NotFoundPage
